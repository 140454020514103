// stylelint-disable declaration-no-important

.mwd-masthead {
  position: relative;
  padding: 3rem ($grid-gutter-width / 2);

  h1 {
    line-height: 1;
  }

  .btn {
    width: 100%;
    padding: .8rem 2rem;
    font-size: 1.25rem;
    font-weight: 500;
  }

  @include media-breakpoint-up(md) {
    padding-top: 5rem;
    padding-bottom: 5rem;

    h1 {
      font-size: 4rem;
    }
  }
}

.half-rule {
  width: 6rem;
  margin: 2.5rem 0;
}

.masthead-followup {
  padding: 3rem ($grid-gutter-width / 2);

  .mwd-clipboard { display: none; }

  .highlight {
    padding: .5rem 0;
    background-color: transparent;
  }
}

/*!
 * Melodic Docs (https://melodic.helloinspire.com)
 * Adapted from Bootstrap Docs (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Portions copyright 2018 Inspire Energy Holdings, LLC
 * Licensed under the Creative Commons Attribution 3.0 Unported License. For
 * details, see https://creativecommons.org/licenses/by/3.0/.
 */
.mwd-navbar {
  min-height: 4rem;
  background-color: #cf126d;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 991.98px) {
  .mwd-navbar .navbar-nav-scroll {
    max-width: 100%;
    height: 2.5rem;
    margin-top: .25rem;
    overflow: hidden;
    font-size: .875rem;
  }
  .mwd-navbar .navbar-nav-scroll .navbar-nav {
    padding-bottom: 2rem;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
}

@media (min-width: 768px) {
  .mwd-navbar {
    background-image: linear-gradient(to right, #2b6ca3, #cf126d 50%, #ffa867);
    background-repeat: no-repeat;
  }
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .mwd-navbar {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1071;
    }
  }
}

.mwd-navbar .navbar-nav .nav-link {
  padding-right: .5rem;
  padding-left: .5rem;
}

.mwd-navbar .navbar-nav .nav-link.active, .mwd-navbar .navbar-nav .nav-link:hover {
  background-color: transparent;
}

.mwd-navbar .navbar-nav .nav-link.active {
  font-weight: 500;
}

.mwd-navbar .navbar-nav-svg {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-top;
}

.mwd-navbar .dropdown-menu {
  font-size: .875rem;
}

.mwd-navbar .dropdown-item.active {
  font-weight: 500;
  color: #212529;
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23292b2c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: .4rem .6rem;
  background-size: .75rem .75rem;
}

.mwd-masthead {
  position: relative;
  padding: 3rem 15px;
}

.mwd-masthead h1 {
  line-height: 1;
}

.mwd-masthead .btn {
  width: 100%;
  padding: .8rem 2rem;
  font-size: 1.25rem;
  font-weight: 500;
}

@media (min-width: 768px) {
  .mwd-masthead {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .mwd-masthead h1 {
    font-size: 4rem;
  }
}

.half-rule {
  width: 6rem;
  margin: 2.5rem 0;
}

.masthead-followup {
  padding: 3rem 15px;
}

.masthead-followup .mwd-clipboard {
  display: none;
}

.masthead-followup .highlight {
  padding: .5rem 0;
  background-color: transparent;
}

.mwd-content {
  -ms-flex-order: 1;
  order: 1;
}

.mwd-content > h2[id],
.mwd-content > h3[id],
.mwd-content > h4[id] {
  pointer-events: none;
}

.mwd-content > h2[id] > div,
.mwd-content > h2[id] > a,
.mwd-content > h3[id] > div,
.mwd-content > h3[id] > a,
.mwd-content > h4[id] > div,
.mwd-content > h4[id] > a {
  pointer-events: auto;
}

.mwd-content > h2[id]::before,
.mwd-content > h3[id]::before,
.mwd-content > h4[id]::before {
  display: block;
  height: 6rem;
  margin-top: -6rem;
  visibility: hidden;
  content: "";
}

.mwd-content > table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

@media (max-width: 991.98px) {
  .mwd-content > table {
    display: block;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .mwd-content > table.table-bordered {
    border: 0;
  }
}

.mwd-content > table > thead > tr > th,
.mwd-content > table > thead > tr > td,
.mwd-content > table > tbody > tr > th,
.mwd-content > table > tbody > tr > td,
.mwd-content > table > tfoot > tr > th,
.mwd-content > table > tfoot > tr > td {
  padding: 0.75rem;
  vertical-align: top;
  border: 1px solid #dee2e6;
}

.mwd-content > table > thead > tr > th > p:last-child,
.mwd-content > table > thead > tr > td > p:last-child,
.mwd-content > table > tbody > tr > th > p:last-child,
.mwd-content > table > tbody > tr > td > p:last-child,
.mwd-content > table > tfoot > tr > th > p:last-child,
.mwd-content > table > tfoot > tr > td > p:last-child {
  margin-bottom: 0;
}

.mwd-content > table td:first-child > code {
  white-space: nowrap;
}

.mwd-content > h2:not(:first-child) {
  margin-top: 3rem;
}

.mwd-content > h3 {
  margin-top: 1.5rem;
}

.mwd-content > ul li,
.mwd-content > ol li {
  margin-bottom: .25rem;
}

@media (min-width: 992px) {
  .mwd-content > ul,
  .mwd-content > ol,
  .mwd-content > p {
    max-width: 80%;
  }
}

.mwd-title {
  margin-top: 1rem;
  margin-bottom: .5rem;
  font-weight: 300;
}

@media (min-width: 576px) {
  .mwd-title {
    font-size: 3rem;
  }
}

.mwd-lead {
  font-size: 1.125rem;
  font-weight: 300;
}

@media (min-width: 576px) {
  .mwd-lead {
    max-width: 80%;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
}

.mwd-text-rosewood {
  color: #cf126d;
}

.mwd-text-rosewood-bright {
  color: #f43a93;
}

#skippy {
  display: block;
  padding: 1em;
  color: #fff;
  background-color: #cf126d;
  outline: 0;
}

#skippy .skiplink-text {
  padding: .5em;
  outline: 1px dotted;
}

.mwd-toc {
  -ms-flex-order: 2;
  order: 2;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: .875rem;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .mwd-toc {
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    height: calc(100vh - 4rem);
    overflow-y: auto;
  }
}

.section-nav {
  padding-left: 0;
  border-left: 1px solid #eee;
}

.section-nav ul {
  padding-left: 1rem;
}

.section-nav ul ul {
  display: none;
}

.toc-entry {
  display: block;
}

.toc-entry a {
  display: block;
  padding: .125rem 1.5rem;
  color: #99979c;
}

.toc-entry a:hover {
  color: #2b6ca3;
  text-decoration: none;
}

.mwd-sidebar {
  -ms-flex-order: 0;
  order: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .mwd-sidebar {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .mwd-sidebar {
      position: -webkit-sticky;
      position: sticky;
      top: 4rem;
      z-index: 1000;
      height: calc(100vh - 4rem);
    }
  }
}

@media (min-width: 1200px) {
  .mwd-sidebar {
    -ms-flex: 0 1 320px;
    flex: 0 1 320px;
  }
}

.mwd-links {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-right: -15px;
  margin-left: -15px;
}

@media (min-width: 768px) {
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .mwd-links {
      max-height: calc(100vh - 9rem);
      overflow-y: auto;
    }
  }
}

@media (min-width: 768px) {
  .mwd-links {
    display: block !important;
  }
}

.mwd-search {
  position: relative;
  padding: 1rem 15px;
  margin-right: -15px;
  margin-left: -15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.mwd-search .form-control:focus {
  border-color: #f43a93;
  box-shadow: 0 0 0 3px rgba(244, 58, 147, 0.25);
}

.mwd-search-docs-toggle {
  line-height: 1;
  color: #212529;
}

.mwd-sidenav {
  display: none;
}

.mwd-toc-link {
  display: block;
  padding: .25rem 1.5rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
}

.mwd-toc-link:hover {
  color: rgba(0, 0, 0, 0.85);
  text-decoration: none;
}

.mwd-toc-item.active {
  margin-bottom: 1rem;
}

.mwd-toc-item.active:not(:first-child) {
  margin-top: 1rem;
}

.mwd-toc-item.active > .mwd-toc-link {
  color: rgba(0, 0, 0, 0.85);
}

.mwd-toc-item.active > .mwd-toc-link:hover {
  background-color: transparent;
}

.mwd-toc-item.active > .mwd-sidenav {
  display: block;
}

.mwd-sidebar .nav > li > a {
  display: block;
  padding: .25rem 1.5rem;
  font-size: 90%;
  color: rgba(0, 0, 0, 0.65);
}

.mwd-sidebar .nav > li > a:hover {
  color: rgba(0, 0, 0, 0.85);
  text-decoration: none;
  background-color: transparent;
}

.mwd-sidebar .nav > .active > a,
.mwd-sidebar .nav > .active:hover > a {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  background-color: transparent;
}

.mwd-footer {
  font-size: 85%;
  text-align: center;
  background-color: #f7f7f7;
}

.mwd-footer a {
  font-weight: 500;
  color: #495057;
}

.mwd-footer a:hover, .mwd-footer a:focus {
  color: #cf126d;
}

.mwd-footer p {
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .mwd-footer {
    text-align: left;
  }
}

.mwd-footer-links {
  padding-left: 0;
  margin-bottom: 1rem;
}

.mwd-footer-links li {
  display: inline-block;
}

.mwd-footer-links li + li {
  margin-left: 1rem;
}

.mwd-example-row .row > .col,
.mwd-example-row .row > [class^="col-"] {
  padding-top: .75rem;
  padding-bottom: .75rem;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.2);
}

.mwd-example-row .row + .row {
  margin-top: 1rem;
}

.mwd-example-row .flex-items-top,
.mwd-example-row .flex-items-middle,
.mwd-example-row .flex-items-bottom {
  min-height: 6rem;
  background-color: rgba(255, 0, 0, 0.1);
}

.mwd-example-row-flex-cols .row {
  min-height: 10rem;
  background-color: rgba(255, 0, 0, 0.1);
}

.mwd-highlight {
  background-color: rgba(207, 18, 109, 0.15);
  border: 1px solid rgba(207, 18, 109, 0.15);
}

.example-container {
  width: 800px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.example-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.example-content-main {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 576px) {
  .example-content-main {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  .example-content-main {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}

.example-content-secondary {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 576px) {
  .example-content-secondary {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  .example-content-secondary {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

.mwd-example-container {
  min-width: 16rem;
  max-width: 25rem;
  margin-right: auto;
  margin-left: auto;
}

.mwd-example-container-header {
  height: 3rem;
  margin-bottom: .5rem;
  background-color: #ffa867;
  border-radius: .25rem;
}

.mwd-example-container-sidebar {
  float: right;
  width: 4rem;
  height: 8rem;
  background-color: #ffe187;
  border-radius: .25rem;
}

.mwd-example-container-body {
  height: 8rem;
  margin-right: 4.5rem;
  background-color: #f26eae;
  border-radius: .25rem;
}

.mwd-example-container-fluid {
  max-width: none;
}

.mwd-example {
  position: relative;
  padding: 1rem;
  margin: 1rem -15px 0;
  border: solid #f7f7f9;
  border-width: .2rem 0 0;
}

.mwd-example::after {
  display: block;
  clear: both;
  content: "";
}

@media (min-width: 576px) {
  .mwd-example {
    padding: 1.5rem;
    margin-right: 0;
    margin-left: 0;
    border-width: .2rem;
  }
}

.mwd-example + .highlight,
.mwd-example + .clipboard + .highlight {
  margin-top: 0;
}

.mwd-example + p {
  margin-top: 2rem;
}

.mwd-example .pos-f-t {
  position: relative;
  margin: -1rem;
}

@media (min-width: 576px) {
  .mwd-example .pos-f-t {
    margin: -1.5rem;
  }
}

.mwd-example .custom-file-input:lang(es) ~ .custom-file-label::after {
  content: "Elegir";
}

.mwd-example > .form-control + .form-control {
  margin-top: .5rem;
}

.mwd-example > .nav + .nav,
.mwd-example > .alert + .alert,
.mwd-example > .navbar + .navbar,
.mwd-example > .progress + .progress,
.mwd-example > .progress + .btn {
  margin-top: 1rem;
}

.mwd-example > .dropdown-menu:first-child {
  position: static;
  display: block;
}

.mwd-example > .form-group:last-child {
  margin-bottom: 0;
}

.mwd-example > .close {
  float: none;
}

.mwd-example-type .table .type-info {
  color: #999;
  vertical-align: middle;
}

.mwd-example-type .table td {
  padding: 1rem 0;
  border-color: #eee;
}

.mwd-example-type .table tr:first-child td {
  border-top: 0;
}

.mwd-example-type h1,
.mwd-example-type h2,
.mwd-example-type h3,
.mwd-example-type h4,
.mwd-example-type h5,
.mwd-example-type h6 {
  margin-top: 0;
  margin-bottom: 0;
}

.mwd-example-bg-classes p {
  padding: 1rem;
}

.mwd-example > img + img {
  margin-left: .5rem;
}

.mwd-example > .btn-group {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.mwd-example > .btn-toolbar + .btn-toolbar {
  margin-top: .5rem;
}

.mwd-example-control-sizing select,
.mwd-example-control-sizing input[type="text"] + input[type="text"] {
  margin-top: .5rem;
}

.mwd-example-form .input-group {
  margin-bottom: .5rem;
}

.mwd-example > textarea.form-control {
  resize: vertical;
}

.mwd-example > .list-group {
  max-width: 400px;
}

.mwd-example .fixed-top,
.mwd-example .sticky-top {
  position: static;
  margin: -1rem -1rem 1rem;
}

.mwd-example .fixed-bottom {
  position: static;
  margin: 1rem -1rem -1rem;
}

@media (min-width: 576px) {
  .mwd-example .fixed-top,
  .mwd-example .sticky-top {
    margin: -1.5rem -1.5rem 1rem;
  }
  .mwd-example .fixed-bottom {
    margin: 1rem -1.5rem -1.5rem;
  }
}

.mwd-example .pagination {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.modal {
  z-index: 1072;
}

.modal .tooltip,
.modal .popover {
  z-index: 1073;
}

.modal-backdrop {
  z-index: 1071;
}

.mwd-example-modal {
  background-color: #fafafa;
}

.mwd-example-modal .modal {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block;
}

.mwd-example-modal .modal-dialog {
  left: auto;
  margin-right: auto;
  margin-left: auto;
}

.mwd-example-tabs .nav-tabs {
  margin-bottom: 1rem;
}

.mwd-example-popover-static {
  padding-bottom: 1.5rem;
  background-color: #f9f9f9;
}

.mwd-example-popover-static .popover {
  position: relative;
  display: block;
  float: left;
  width: 260px;
  margin: 1.25rem;
}

.tooltip-demo a {
  white-space: nowrap;
}

.mwd-example-tooltip-static .tooltip {
  position: relative;
  display: inline-block;
  margin: 10px 20px;
  opacity: 1;
}

.scrollspy-example {
  position: relative;
  height: 200px;
  margin-top: .5rem;
  overflow: auto;
}

.scrollspy-example-2 {
  position: relative;
  height: 350px;
  overflow: auto;
}

.mwd-example-border-utils [class^="border"] {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  margin: .25rem;
  background-color: #f5f5f5;
}

.mwd-example-border-utils-0 [class^="border"] {
  border: 1px solid #dee2e6;
}

.highlight {
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #f7f7f9;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

@media (min-width: 576px) {
  .highlight {
    padding: 1.5rem;
  }
}

.mwd-content .highlight {
  margin-right: -15px;
  margin-left: -15px;
}

@media (min-width: 576px) {
  .mwd-content .highlight {
    margin-right: 0;
    margin-left: 0;
  }
}

.highlight pre {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  background-color: transparent;
  border: 0;
}

.highlight pre code {
  font-size: inherit;
  color: #212529;
}

.mwd-callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  border-left-width: .25rem;
  border-radius: .25rem;
}

.mwd-callout h4 {
  margin-top: 0;
  margin-bottom: .25rem;
}

.mwd-callout p:last-child {
  margin-bottom: 0;
}

.mwd-callout code {
  border-radius: .25rem;
}

.mwd-callout + .mwd-callout {
  margin-top: -.25rem;
}

.mwd-callout-info {
  border-left-color: #5bc0de;
}

.mwd-callout-info h4 {
  color: #5bc0de;
}

.mwd-callout-warning {
  border-left-color: #f8cc1c;
}

.mwd-callout-warning h4 {
  color: #f8cc1c;
}

.mwd-callout-danger {
  border-left-color: #d9534f;
}

.mwd-callout-danger h4 {
  color: #d9534f;
}

.mwd-examples .img-thumbnail {
  margin-bottom: .75rem;
}

.mwd-examples h4 {
  margin-bottom: .25rem;
}

.mwd-examples p {
  margin-bottom: 1.25rem;
}

@media (max-width: 480px) {
  .mwd-examples {
    margin-right: -.75rem;
    margin-left: -.75rem;
  }
  .mwd-examples > [class^="col-"] {
    padding-right: .75rem;
    padding-left: .75rem;
  }
}

.mwd-browser-bugs td p {
  margin-bottom: 0;
}

.mwd-browser-bugs th:first-child {
  width: 18%;
}

.color-swatch {
  float: left;
  width: 4rem;
  height: 4rem;
  margin-right: .25rem;
  margin-left: .25rem;
  border-radius: .25rem;
}

@media (min-width: 768px) {
  .color-swatch {
    width: 6rem;
    height: 6rem;
  }
}

.color-swatches {
  margin: 0 -5px;
  overflow: hidden;
}

.color-swatches .mwd-rosewood {
  background-color: #cf126d;
}

.color-swatches .mwd-rosewood-light {
  background-color: #fccbe2;
}

.color-swatches .mwd-rosewood-lighter {
  background-color: #e5e1ea;
}

.color-swatches .mwd-gray {
  background-color: #f9f9f9;
}

.swatch-rosewood {
  color: #fff;
  background-color: #cf126d;
}

.swatch-sand {
  color: #1b1b1b;
  background-color: #efdbcb;
}

.swatch-cosmos {
  color: #fff;
  background-color: #1b1b1b;
}

.swatch-malachite {
  color: #fff;
  background-color: #00524d;
}

.swatch-pacific {
  color: #fff;
  background-color: #2b6ca3;
}

.swatch-harvest {
  color: #1b1b1b;
  background-color: #ffa867;
}

.swatch-blue-violet {
  color: #fff;
  background-color: #5147ad;
}

.swatch-indigo {
  color: #fff;
  background-color: #6610f2;
}

.swatch-purple {
  color: #fff;
  background-color: #6f42c1;
}

.swatch-red {
  color: #fff;
  background-color: #dc3545;
}

.swatch-orange {
  color: #1b1b1b;
  background-color: #fd7e14;
}

.swatch-yellow {
  color: #1b1b1b;
  background-color: #ffc107;
}

.swatch-mango {
  color: #1b1b1b;
  background-color: #f8cc1c;
}

.swatch-vis-vis {
  color: #1b1b1b;
  background-color: #fff5a5;
}

.swatch-green {
  color: #fff;
  background-color: #28a745;
}

.swatch-teal {
  color: #fff;
  background-color: #20c997;
}

.swatch-cyan {
  color: #fff;
  background-color: #17a2b8;
}

.swatch-white {
  color: #1b1b1b;
  background-color: #fff;
}

.swatch-gray {
  color: #fff;
  background-color: #6c757d;
}

.swatch-primary {
  color: #fff;
  background-color: #cf126d;
}

.swatch-secondary {
  color: #fff;
  background-color: #6c757d;
}

.swatch-success {
  color: #fff;
  background-color: #00524d;
}

.swatch-info {
  color: #fff;
  background-color: #2b6ca3;
}

.swatch-warning {
  color: #1b1b1b;
  background-color: #ffa867;
}

.swatch-danger {
  color: #fff;
  background-color: #dc3545;
}

.swatch-light {
  color: #1b1b1b;
  background-color: #f8f9fa;
}

.swatch-dark {
  color: #fff;
  background-color: #1b1b1b;
}

.swatch-100 {
  color: #1b1b1b;
  background-color: #f8f9fa;
}

.swatch-200 {
  color: #1b1b1b;
  background-color: #e9ecef;
}

.swatch-300 {
  color: #1b1b1b;
  background-color: #dee2e6;
}

.swatch-400 {
  color: #1b1b1b;
  background-color: #ced4da;
}

.swatch-500 {
  color: #1b1b1b;
  background-color: #adb5bd;
}

.swatch-600 {
  color: #fff;
  background-color: #6c757d;
}

.swatch-700 {
  color: #fff;
  background-color: #495057;
}

.swatch-800 {
  color: #fff;
  background-color: #343a40;
}

.swatch-900 {
  color: #fff;
  background-color: #212529;
}

.mwd-clipboard {
  position: relative;
  display: none;
  float: right;
}

.mwd-clipboard + .highlight {
  margin-top: 0;
}

.btn-clipboard {
  position: absolute;
  top: .5rem;
  right: .5rem;
  z-index: 10;
  display: block;
  padding: .25rem .5rem;
  font-size: 75%;
  color: #818a91;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  border-radius: .25rem;
}

.btn-clipboard:hover {
  color: #fff;
  background-color: #027de7;
}

@media (min-width: 768px) {
  .mwd-clipboard {
    display: block;
  }
}

.brand-swatch-rosewood {
  color: #fff;
  background-color: #cf126d;
}

.brand-swatch-rosewood-tint-10 {
  color: #fff;
  background-color: #d42a7c;
}

.brand-swatch-rosewood-shade-10 {
  color: #fff;
  background-color: #ba1062;
}

.brand-swatch-rosewood-tint-20 {
  color: #fff;
  background-color: #d9418a;
}

.brand-swatch-rosewood-shade-20 {
  color: #fff;
  background-color: #a60e57;
}

.brand-swatch-rosewood-tint-30 {
  color: #fff;
  background-color: #dd5999;
}

.brand-swatch-rosewood-shade-30 {
  color: #fff;
  background-color: #910d4c;
}

.brand-swatch-rosewood-tint-40 {
  color: #1b1b1b;
  background-color: #e271a7;
}

.brand-swatch-rosewood-shade-40 {
  color: #fff;
  background-color: #7c0b41;
}

.brand-swatch-rosewood-tint-50 {
  color: #1b1b1b;
  background-color: #e789b6;
}

.brand-swatch-rosewood-shade-50 {
  color: #fff;
  background-color: #680937;
}

.brand-swatch-rosewood-tint-60 {
  color: #1b1b1b;
  background-color: #eca0c5;
}

.brand-swatch-rosewood-shade-60 {
  color: #fff;
  background-color: #53072c;
}

.brand-swatch-rosewood-tint-70 {
  color: #1b1b1b;
  background-color: #f1b8d3;
}

.brand-swatch-rosewood-shade-70 {
  color: #fff;
  background-color: #3e0521;
}

.brand-swatch-rosewood-tint-80 {
  color: #1b1b1b;
  background-color: #f5d0e2;
}

.brand-swatch-rosewood-shade-80 {
  color: #fff;
  background-color: #290416;
}

.brand-swatch-rosewood-tint-90 {
  color: #1b1b1b;
  background-color: #fae7f0;
}

.brand-swatch-rosewood-shade-90 {
  color: #fff;
  background-color: #15020b;
}

.brand-swatch-rosewood-tint-100 {
  color: #1b1b1b;
  background-color: white;
}

.brand-swatch-rosewood-shade-100 {
  color: #fff;
  background-color: black;
}

.brand-swatch-sand {
  color: #1b1b1b;
  background-color: #efdbcb;
}

.brand-swatch-sand-tint-10 {
  color: #1b1b1b;
  background-color: #f1dfd0;
}

.brand-swatch-sand-shade-10 {
  color: #1b1b1b;
  background-color: #d7c5b7;
}

.brand-swatch-sand-tint-20 {
  color: #1b1b1b;
  background-color: #f2e2d5;
}

.brand-swatch-sand-shade-20 {
  color: #1b1b1b;
  background-color: #bfafa2;
}

.brand-swatch-sand-tint-30 {
  color: #1b1b1b;
  background-color: #f4e6db;
}

.brand-swatch-sand-shade-30 {
  color: #1b1b1b;
  background-color: #a7998e;
}

.brand-swatch-sand-tint-40 {
  color: #1b1b1b;
  background-color: #f5e9e0;
}

.brand-swatch-sand-shade-40 {
  color: #fff;
  background-color: #8f837a;
}

.brand-swatch-sand-tint-50 {
  color: #1b1b1b;
  background-color: #f7ede5;
}

.brand-swatch-sand-shade-50 {
  color: #fff;
  background-color: #786e66;
}

.brand-swatch-sand-tint-60 {
  color: #1b1b1b;
  background-color: #f9f1ea;
}

.brand-swatch-sand-shade-60 {
  color: #fff;
  background-color: #605851;
}

.brand-swatch-sand-tint-70 {
  color: #1b1b1b;
  background-color: #faf4ef;
}

.brand-swatch-sand-shade-70 {
  color: #fff;
  background-color: #48423d;
}

.brand-swatch-sand-tint-80 {
  color: #1b1b1b;
  background-color: #fcf8f5;
}

.brand-swatch-sand-shade-80 {
  color: #fff;
  background-color: #302c29;
}

.brand-swatch-sand-tint-90 {
  color: #1b1b1b;
  background-color: #fdfbfa;
}

.brand-swatch-sand-shade-90 {
  color: #fff;
  background-color: #181614;
}

.brand-swatch-sand-tint-100 {
  color: #1b1b1b;
  background-color: white;
}

.brand-swatch-sand-shade-100 {
  color: #fff;
  background-color: black;
}

.brand-swatch-cosmos {
  color: #fff;
  background-color: #1b1b1b;
}

.brand-swatch-cosmos-tint-10 {
  color: #fff;
  background-color: #323232;
}

.brand-swatch-cosmos-shade-10 {
  color: #fff;
  background-color: #181818;
}

.brand-swatch-cosmos-tint-20 {
  color: #fff;
  background-color: #494949;
}

.brand-swatch-cosmos-shade-20 {
  color: #fff;
  background-color: #161616;
}

.brand-swatch-cosmos-tint-30 {
  color: #fff;
  background-color: #5f5f5f;
}

.brand-swatch-cosmos-shade-30 {
  color: #fff;
  background-color: #131313;
}

.brand-swatch-cosmos-tint-40 {
  color: #fff;
  background-color: #767676;
}

.brand-swatch-cosmos-shade-40 {
  color: #fff;
  background-color: #101010;
}

.brand-swatch-cosmos-tint-50 {
  color: #fff;
  background-color: #8d8d8d;
}

.brand-swatch-cosmos-shade-50 {
  color: #fff;
  background-color: #0e0e0e;
}

.brand-swatch-cosmos-tint-60 {
  color: #1b1b1b;
  background-color: #a4a4a4;
}

.brand-swatch-cosmos-shade-60 {
  color: #fff;
  background-color: #0b0b0b;
}

.brand-swatch-cosmos-tint-70 {
  color: #1b1b1b;
  background-color: #bbbbbb;
}

.brand-swatch-cosmos-shade-70 {
  color: #fff;
  background-color: #080808;
}

.brand-swatch-cosmos-tint-80 {
  color: #1b1b1b;
  background-color: #d1d1d1;
}

.brand-swatch-cosmos-shade-80 {
  color: #fff;
  background-color: #050505;
}

.brand-swatch-cosmos-tint-90 {
  color: #1b1b1b;
  background-color: #e8e8e8;
}

.brand-swatch-cosmos-shade-90 {
  color: #fff;
  background-color: #030303;
}

.brand-swatch-cosmos-tint-100 {
  color: #1b1b1b;
  background-color: white;
}

.brand-swatch-cosmos-shade-100 {
  color: #fff;
  background-color: black;
}

.brand-swatch-malachite {
  color: #fff;
  background-color: #00524d;
}

.brand-swatch-malachite-tint-10 {
  color: #fff;
  background-color: #1a635f;
}

.brand-swatch-malachite-shade-10 {
  color: #fff;
  background-color: #004a45;
}

.brand-swatch-malachite-tint-20 {
  color: #fff;
  background-color: #337571;
}

.brand-swatch-malachite-shade-20 {
  color: #fff;
  background-color: #00423e;
}

.brand-swatch-malachite-tint-30 {
  color: #fff;
  background-color: #4d8682;
}

.brand-swatch-malachite-shade-30 {
  color: #fff;
  background-color: #003936;
}

.brand-swatch-malachite-tint-40 {
  color: #fff;
  background-color: #669794;
}

.brand-swatch-malachite-shade-40 {
  color: #fff;
  background-color: #00312e;
}

.brand-swatch-malachite-tint-50 {
  color: #1b1b1b;
  background-color: #80a9a6;
}

.brand-swatch-malachite-shade-50 {
  color: #fff;
  background-color: #002927;
}

.brand-swatch-malachite-tint-60 {
  color: #1b1b1b;
  background-color: #99bab8;
}

.brand-swatch-malachite-shade-60 {
  color: #fff;
  background-color: #00211f;
}

.brand-swatch-malachite-tint-70 {
  color: #1b1b1b;
  background-color: #b3cbca;
}

.brand-swatch-malachite-shade-70 {
  color: #fff;
  background-color: #001917;
}

.brand-swatch-malachite-tint-80 {
  color: #1b1b1b;
  background-color: #ccdcdb;
}

.brand-swatch-malachite-shade-80 {
  color: #fff;
  background-color: #00100f;
}

.brand-swatch-malachite-tint-90 {
  color: #1b1b1b;
  background-color: #e6eeed;
}

.brand-swatch-malachite-shade-90 {
  color: #fff;
  background-color: #000808;
}

.brand-swatch-malachite-tint-100 {
  color: #1b1b1b;
  background-color: white;
}

.brand-swatch-malachite-shade-100 {
  color: #fff;
  background-color: black;
}

.brand-swatch-pacific {
  color: #fff;
  background-color: #2b6ca3;
}

.brand-swatch-pacific-tint-10 {
  color: #fff;
  background-color: #407bac;
}

.brand-swatch-pacific-shade-10 {
  color: #fff;
  background-color: #276193;
}

.brand-swatch-pacific-tint-20 {
  color: #fff;
  background-color: #5589b5;
}

.brand-swatch-pacific-shade-20 {
  color: #fff;
  background-color: #225682;
}

.brand-swatch-pacific-tint-30 {
  color: #fff;
  background-color: #6b98bf;
}

.brand-swatch-pacific-shade-30 {
  color: #fff;
  background-color: #1e4c72;
}

.brand-swatch-pacific-tint-40 {
  color: #1b1b1b;
  background-color: #80a7c8;
}

.brand-swatch-pacific-shade-40 {
  color: #fff;
  background-color: #1a4162;
}

.brand-swatch-pacific-tint-50 {
  color: #1b1b1b;
  background-color: #95b6d1;
}

.brand-swatch-pacific-shade-50 {
  color: #fff;
  background-color: #163652;
}

.brand-swatch-pacific-tint-60 {
  color: #1b1b1b;
  background-color: #aac4da;
}

.brand-swatch-pacific-shade-60 {
  color: #fff;
  background-color: #112b41;
}

.brand-swatch-pacific-tint-70 {
  color: #1b1b1b;
  background-color: #bfd3e3;
}

.brand-swatch-pacific-shade-70 {
  color: #fff;
  background-color: #0d2031;
}

.brand-swatch-pacific-tint-80 {
  color: #1b1b1b;
  background-color: #d5e2ed;
}

.brand-swatch-pacific-shade-80 {
  color: #fff;
  background-color: #091621;
}

.brand-swatch-pacific-tint-90 {
  color: #1b1b1b;
  background-color: #eaf0f6;
}

.brand-swatch-pacific-shade-90 {
  color: #fff;
  background-color: #040b10;
}

.brand-swatch-pacific-tint-100 {
  color: #1b1b1b;
  background-color: white;
}

.brand-swatch-pacific-shade-100 {
  color: #fff;
  background-color: black;
}

.brand-swatch-harvest {
  color: #1b1b1b;
  background-color: #ffa867;
}

.brand-swatch-harvest-tint-10 {
  color: #1b1b1b;
  background-color: #ffb176;
}

.brand-swatch-harvest-shade-10 {
  color: #1b1b1b;
  background-color: #e6975d;
}

.brand-swatch-harvest-tint-20 {
  color: #1b1b1b;
  background-color: #ffb985;
}

.brand-swatch-harvest-shade-20 {
  color: #fff;
  background-color: #cc8652;
}

.brand-swatch-harvest-tint-30 {
  color: #1b1b1b;
  background-color: #ffc295;
}

.brand-swatch-harvest-shade-30 {
  color: #fff;
  background-color: #b37648;
}

.brand-swatch-harvest-tint-40 {
  color: #1b1b1b;
  background-color: #ffcba4;
}

.brand-swatch-harvest-shade-40 {
  color: #fff;
  background-color: #99653e;
}

.brand-swatch-harvest-tint-50 {
  color: #1b1b1b;
  background-color: #ffd4b3;
}

.brand-swatch-harvest-shade-50 {
  color: #fff;
  background-color: #805434;
}

.brand-swatch-harvest-tint-60 {
  color: #1b1b1b;
  background-color: #ffdcc2;
}

.brand-swatch-harvest-shade-60 {
  color: #fff;
  background-color: #664329;
}

.brand-swatch-harvest-tint-70 {
  color: #1b1b1b;
  background-color: #ffe5d1;
}

.brand-swatch-harvest-shade-70 {
  color: #fff;
  background-color: #4d321f;
}

.brand-swatch-harvest-tint-80 {
  color: #1b1b1b;
  background-color: #ffeee1;
}

.brand-swatch-harvest-shade-80 {
  color: #fff;
  background-color: #332215;
}

.brand-swatch-harvest-tint-90 {
  color: #1b1b1b;
  background-color: #fff6f0;
}

.brand-swatch-harvest-shade-90 {
  color: #fff;
  background-color: #1a110a;
}

.brand-swatch-harvest-tint-100 {
  color: #1b1b1b;
  background-color: white;
}

.brand-swatch-harvest-shade-100 {
  color: #fff;
  background-color: black;
}

.hll {
  background-color: #ffc;
}

.c {
  color: #999;
}

.k {
  color: #069;
}

.o {
  color: #555;
}

.cm {
  color: #999;
}

.cp {
  color: #099;
}

.c1 {
  color: #999;
}

.cs {
  color: #999;
}

.gd {
  background-color: #fcc;
  border: 1px solid #c00;
}

.ge {
  font-style: italic;
}

.gr {
  color: #f00;
}

.gh {
  color: #030;
}

.gi {
  background-color: #cfc;
  border: 1px solid #0c0;
}

.go {
  color: #aaa;
}

.gp {
  color: #009;
}

.gu {
  color: #030;
}

.gt {
  color: #9c6;
}

.kc {
  color: #069;
}

.kd {
  color: #069;
}

.kn {
  color: #069;
}

.kp {
  color: #069;
}

.kr {
  color: #069;
}

.kt {
  color: #078;
}

.m {
  color: #f60;
}

.s {
  color: #d44950;
}

.na {
  color: #4f9fcf;
}

.nb {
  color: #366;
}

.nc {
  color: #0a8;
}

.no {
  color: #360;
}

.nd {
  color: #99f;
}

.ni {
  color: #999;
}

.ne {
  color: #c00;
}

.nf {
  color: #c0f;
}

.nl {
  color: #99f;
}

.nn {
  color: #0cf;
}

.nt {
  color: #2f6f9f;
}

.nv {
  color: #033;
}

.ow {
  color: #000;
}

.w {
  color: #bbb;
}

.mf {
  color: #f60;
}

.mh {
  color: #f60;
}

.mi {
  color: #f60;
}

.mo {
  color: #f60;
}

.sb {
  color: #c30;
}

.sc {
  color: #c30;
}

.sd {
  font-style: italic;
  color: #c30;
}

.s2 {
  color: #c30;
}

.se {
  color: #c30;
}

.sh {
  color: #c30;
}

.si {
  color: #a00;
}

.sx {
  color: #c30;
}

.sr {
  color: #3aa;
}

.s1 {
  color: #c30;
}

.ss {
  color: #fc3;
}

.bp {
  color: #366;
}

.vc {
  color: #033;
}

.vg {
  color: #033;
}

.vi {
  color: #033;
}

.il {
  color: #f60;
}

.css .o,
.css .o + .nt,
.css .nt + .nt {
  color: #999;
}

.language-bash::before,
.language-sh::before {
  color: #009;
  content: "$ ";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.language-powershell::before {
  color: #009;
  content: "PM> ";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.anchorjs-link {
  font-weight: 400;
  color: rgba(207, 18, 109, 0.5);
  transition: color .16s linear;
}

.anchorjs-link:hover {
  color: #cf126d;
  text-decoration: none;
}

.algolia-autocomplete {
  display: block !important;
  -ms-flex: 1;
  flex: 1;
}

.algolia-autocomplete .ds-dropdown-menu {
  width: 100%;
  min-width: 0 !important;
  max-width: none !important;
  padding: .75rem 0 !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
}

@media (min-width: 768px) {
  .algolia-autocomplete .ds-dropdown-menu {
    width: 175%;
  }
}

.algolia-autocomplete .ds-dropdown-menu::before {
  display: none !important;
}

.algolia-autocomplete .ds-dropdown-menu [class^="ds-dataset-"] {
  padding: 0 !important;
  overflow: visible !important;
  background-color: transparent !important;
  border: 0 !important;
}

.algolia-autocomplete .ds-dropdown-menu .ds-suggestions {
  margin-top: 0 !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion {
  padding: 0 !important;
  overflow: visible !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--category-header {
  padding: .125rem 1rem !important;
  margin-top: 0 !important;
  font-size: .875rem !important;
  font-weight: 500 !important;
  color: #f43a93 !important;
  border-bottom: 0 !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--wrapper {
  float: none !important;
  padding-top: 0 !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--subcategory-column {
  float: none !important;
  width: auto !important;
  padding: 0 !important;
  text-align: left !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--content {
  float: none !important;
  width: auto !important;
  padding: 0 !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--content::before {
  display: none !important;
}

.algolia-autocomplete .ds-suggestion:not(:first-child) .algolia-docsearch-suggestion--category-header {
  padding-top: .75rem !important;
  margin-top: .75rem !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.algolia-autocomplete .ds-suggestion .algolia-docsearch-suggestion--subcategory-column {
  display: none !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--title {
  display: block;
  padding: .25rem 1rem !important;
  margin-bottom: 0 !important;
  font-size: .875rem !important;
  font-weight: 400 !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--text {
  padding: 0 1rem .5rem !important;
  margin-top: -.25rem;
  font-size: .875rem !important;
  font-weight: 400;
  line-height: 1.25 !important;
}

.algolia-autocomplete .algolia-docsearch-footer {
  float: none !important;
  width: auto !important;
  height: auto !important;
  padding: .75rem 1rem 0;
  font-size: .75rem !important;
  line-height: 1 !important;
  color: #767676 !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.algolia-autocomplete .algolia-docsearch-footer--logo {
  display: inline !important;
  overflow: visible !important;
  color: inherit !important;
  text-indent: 0 !important;
  background: none !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--highlight {
  color: #e6168b;
  background-color: rgba(154, 132, 187, 0.12);
}

.algolia-autocomplete .algolia-docsearch-suggestion--text .algolia-docsearch-suggestion--highlight {
  box-shadow: inset 0 -2px 0 0 rgba(95, 45, 171, 0.5) !important;
}

.algolia-autocomplete .ds-suggestion.ds-cursor .algolia-docsearch-suggestion--content {
  background-color: rgba(208, 189, 236, 0.15) !important;
}
/*# sourceMappingURL=docs.min.css.map */
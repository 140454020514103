/*!
 * Melodic Docs (https://melodic.helloinspire.com)
 * Adapted from Bootstrap Docs (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Portions copyright 2018 Inspire Energy Holdings, LLC
 * Licensed under the Creative Commons Attribution 3.0 Unported License. For
 * details, see https://creativecommons.org/licenses/by/3.0/.
 */

// Dev notes
//
// Background information on nomenclature and architecture decisions here.
//
// - Melodic functions, variables, and mixins are included for easy reuse.
//   Doing so gives us access to the same core utilities provided by Melodic.
//   For example, consistent media queries through those mixins.
//
// - Melodic's **docs variables** are prefixed with `$mwd-`.
//   These custom colors avoid collision with the components Melodic provides.
//
// - Classes are prefixed with `.mwd-`.
//   These classes indicate custom-built or modified components for the design
//   and layout of the Melodic docs. They are not included in our builds.
//

// Load Melodic variables and mixins
@import "../../scss/functions";
@import "../../scss/variables";
@import "../../scss/mixins";

// Load docs components
@import "variables";
@import "nav";
@import "masthead";
@import "content";
@import "skiplink";
@import "sidebar";
@import "footer";
@import "component-examples";
@import "callouts";
@import "examples";
@import "browser-bugs";
@import "colors";
@import "clipboard-js";
@import "brand-color-tints-shades";

// Load docs dependencies
@import "syntax";
@import "anchor";
@import "algolia";

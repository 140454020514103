//
// Color swatches
//

.color-swatch {
  float: left;
  width: 4rem;
  height: 4rem;
  margin-right: .25rem;
  margin-left: .25rem;
  border-radius: .25rem;

  @media (min-width: 768px) {
    width: 6rem;
    height: 6rem;
  }
}

// Docs colors
.color-swatches {
  margin: 0 -5px;
  overflow: hidden; // clearfix

  .mwd-rosewood {
    background-color: $mwd-rosewood;
  }
  .mwd-rosewood-light {
    background-color: $mwd-rosewood-light;
  }
  .mwd-rosewood-lighter {
    background-color: #e5e1ea;
  }
  .mwd-gray {
    background-color: #f9f9f9;
  }
}

//
// Docs color palette classes
//

@each $color, $value in $colors {
  .swatch-#{$color} {
    color: color-yiq($value);
    background-color: #{$value};
  }
}

@each $color, $value in $theme-colors {
  .swatch-#{$color} {
    color: color-yiq($value);
    background-color: #{$value};
  }
}

@each $color, $value in $grays {
  .swatch-#{$color} {
    color: color-yiq($value);
    background-color: #{$value};
  }
}

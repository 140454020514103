//
// Callouts
//

.mwd-callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  border-left-width: .25rem;
  border-radius: .25rem;
}

.mwd-callout h4 {
  margin-top: 0;
  margin-bottom: .25rem;
}

.mwd-callout p:last-child {
  margin-bottom: 0;
}

.mwd-callout code {
  border-radius: .25rem;
}

.mwd-callout + .mwd-callout {
  margin-top: -.25rem;
}

// Variations
@mixin mel-callout-variant($color) {
  border-left-color: $color;

  h4 { color: $color; }
}

.mwd-callout-info { @include mel-callout-variant($mwd-info); }
.mwd-callout-warning { @include mel-callout-variant($mwd-warning); }
.mwd-callout-danger { @include mel-callout-variant($mwd-danger); }
